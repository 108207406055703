import { LinkButton } from '@stories/Components/Buttons/Button/Button';
import Heading from '@stories/Components/Global/Typography/Heading';
import Paragraph from '@stories/Components/Global/Typography/Paragraph';
import Image from '@stories/Components/Media/Image/Image';
import React from 'react';
import { PromoCardStyles } from './PromoCard.styles';
const PromoCard = (props) => {
    return (React.createElement(PromoCardStyles.Card, null,
        React.createElement(PromoCardStyles.Media, null,
            React.createElement(Image, { src: props.imageUrl || '', alt: props.title || '', layout: "responsive", width: 526, height: 247, objectFit: "cover", objectPosition: "center" })),
        React.createElement(PromoCardStyles.Content, null,
            React.createElement(Heading, { semantic: "h3", visual: "h5" }, props.title),
            React.createElement(Paragraph, { size: "small" }, props.copy),
            props.cta ? (React.createElement(LinkButton, { title: props.cta.title, branding: props.cta.branding || 'primaryAlt', href: props.cta.url, target: props.cta.target })) : null)));
};
export default PromoCard;
