import brand from '@helpers/brand';
import { Device, from } from '@helpers/media';
import { ButtonStyles } from '@stories/Components/Buttons/Button/Button.styles';
import HeadingStyles from '@stories/Components/Global/Typography/Heading.styles';
import ParagraphStyles from '@stories/Components/Global/Typography/Paragraph.styles';
import styled from 'styled-components';
const Card = styled.div `
  background-color: ${brand.grey['grey20']};
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: ${brand.primary.dark};

    ${ButtonStyles.Inner} {
      background-color: ${brand.white};
      color: ${brand.primary.alt};
    }
  }
`;
const Media = styled.div `
  flex: 0 0 auto;
`;
const Content = styled.div `
  flex: 1 0 auto;
  padding: 36px 18px 48px 18px;
  text-align: center;
  display: flex;
  gap: 26px;
  flex-direction: column;

  @media ${from(Device.Tablet)} {
    padding: 18px 18px 30px 18px;
  }

  ${HeadingStyles.Heading} {
    color: ${brand.white};
    margin-bottom: 0px;
    font-weight: bold;
  }

  ${ParagraphStyles.Paragraph} {
    color: ${brand.white};
    margin-bottom: 0px;
    text-align: center;
  }

  ${ButtonStyles.Button} {
    align-self: center;
    margin-top: auto;
  }
`;
export const PromoCardStyles = {
    Card,
    Media,
    Content,
};
