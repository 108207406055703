import { HydrateOption } from '@core/enums';
import withWidget from '@hoc/withWidget';
import PromoCard from '@stories/Components/Cards/PromoCard/PromoCard';
import React from 'react';
import { PromoCardsStyles as S } from './PromoCards.styles';
const PromoCards = (props) => {
    return (React.createElement(S.Container, null,
        React.createElement(S.CardGrid, null, props.cards.map((x) => (React.createElement(PromoCard, { key: x.title, ...x }))))));
};
export default withWidget(PromoCards, 'PromoCards', { hydrate: HydrateOption.InView });
