import brand from '@helpers/brand';
import { Grid } from '@helpers/grid';
import { Device, from } from '@helpers/media';
import lines from '@img/icons/lines.svg';
import { PromoCardStyles } from '@stories/Components/Cards/PromoCard/PromoCard.styles';
import styled from 'styled-components';
const Container = styled.div `
  position: relative;
  margin: 40px 0px;

  @media ${from(Device.Tablet)} {
    margin: 80px 0px;
  }

  @media ${from(Device.Desktop)} {
    margin: 96px 0px;
  }

  &:before {
    content: '';
    position: absolute;

    transform: translateY(-50%) skewY(-5deg);
    height: 115px;
    width: 100%;
    z-index: 0;
    background: url(${lines});
    background-size: 500px;
    background-repeat: no-repeat;
    top: 52%;
    left: 2%;

    @media ${from(Device.TabletLarge)} {
      top: 59%;
      left: 4%;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%) skewY(-5deg);
    height: 115px;
    width: 100%;
    background-color: ${brand.primary.alt};
    z-index: 0;
  }
`;
const CardGrid = styled(Grid.Default) `
  position: relative;
  z-index: 1;

  ${PromoCardStyles.Card} {
    grid-column: span 12;

    @media ${from(Device.TabletLarge)} {
      grid-column: span 4;
    }
  }
`;
export const PromoCardsStyles = {
    Container,
    CardGrid,
};
